<template>
  <div>
        <a-form-model
          ref="newReportForm"
          :model="form"
          :rules="rules"
        >
          <div class="report-date-type mb-4">
            <a-radio-group v-model="createForMonth" button-style="solid" class="w-100">
              <a-radio-button :value="false" class="w-50 text-center">
                За выбранный период
              </a-radio-button>
              <a-radio-button :value="true" class="w-50 text-center">
                По месяцам
              </a-radio-button>
            </a-radio-group>
          </div>
          <div v-if="!createForMonth" class="report-custom-date">
            <a-row>
              <a-col :span="12" class="pr-1">
                <a-form-model-item ref="from_date" label="Начальная дата" prop="from_date">
                  <a-date-picker
                    v-model="from_date"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    type="date"
                    placeholder="Выберите начальную дату отчета"
                    :default-value="this.$moment('2021/10/20', 'YYYY-MM-DD HH:mm:ss')"
                    @change="handleDatesChange"
                    class="mr-1 w-100"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12" class="pl-1">
                <a-form-model-item ref="to_date" label="Конечная дата" prop="to_date">
                  <a-date-picker
                    v-model="to_date"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    type="date"
                    placeholder="Выберите конечную дату отчета"
                    :default-value="this.$moment('2021/10/20', 'YYYY-MM-DD HH:mm:ss')"
                    @change="handleDatesChange"
                    class="mr-1 w-100"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <div v-else class="report-month">
            <a-form-model-item ref="month" label="Отчетный месяц" prop="month">
              <a-select v-model="selectedMonth" placeholder="Выберите месяц">
                <a-select-option v-for="(monthName, index) in availableMonths" :key="index" >
                  {{ monthName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="mt-2">
            <a-row>
              <a-col :span="12" class="pr-1">
                <a-form-model-item ref="project_id" label="Проект" prop="project_id">
                  <a-select v-model="projectId" placeholder="Выберите проект" class="width-200">
                    <a-select-option :value="0" >
                      [Все проекты]
                    </a-select-option>
                    <a-select-option v-for="project in activeProjectList" :key="project.id" >
                      {{ project.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12" class="pl-1">
                <a-form-model-item ref="account_id" label="Агент" prop="account_id">
                  <a-select v-model="accountId" placeholder="Выберите агента" class="width-200">
                    <a-select-option :value="0" >
                      [Все агенты]
                    </a-select-option>
                    <a-select-option v-for="account in accounts" :key="account.id" >
                      {{ account.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <a-checkbox v-model="downloadAfterCreate" v-if="userHasRole('admin')">
            Скачать файл после создания
          </a-checkbox>
          <hr>
          <div class="d-flex">
            <div>
              <a-button style="margin-left: 10px;" @click="closeModal">
                Отмена
              </a-button>
            </div>
            <div class="ml-auto">
              <span v-if="createLoading" class="text-gray-5 mr-2">
                <small>Генерирую файл. Подождите немного ...</small>
              </span>
              <a-button type="primary" @click="onSubmit" :loading="createLoading">
                {{ userHasRole('admin') ? 'Создать' : 'Скачать' }}
              </a-button>
            </div>
          </div>

        </a-form-model>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

export default {
  name: 'addPush',
  props: ['fromDate', 'toDate', 'accounts', 'accountId', 'projectId'],
  mixins: [accessMix],
  computed: {
    ...mapGetters(['activeProjectList']),
    availableMonths() {
      const avm = []
      const currentMonth = new Date().getMonth()
      this.monthNames.forEach(function (value, i) {
        if (i <= currentMonth) { avm.push(value) }
      })
      console.log('avm', avm)
      return avm
    },
  },
  data() {
    return {
      downloadAfterCreate: true,
      createLoading: false,
      from_date: this.fromDate,
      to_date: this.toDate,
      createForMonth: false,
      monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      selectedMonth: new Date().getMonth(),
      form: {
        from_date: this.$moment(this.fromDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.toDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        year: 0,
        month: 0,
        account_id: 0,
        project_id: 0,
      },
      rules: {
        from_date: [{ required: true, message: 'Укажите начальную дату отчета', trigger: 'change' }],
        to_date: [{ required: true, message: 'Укажите конечную дату отчета', trigger: 'change' }],
      },
    }
  },
  mounted () {
    console.log(this.form.from_date)
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, download) {
      this.$emit('submitModalFunction', record, download)
    },
    handleDatesChange() {
      this.form.from_date = this.$moment(this.from_date).utc().format('YYYY-MM-DD HH:mm:ss')
      this.form.to_date = this.$moment(this.to_date).utc().format('YYYY-MM-DD HH:mm:ss')
      console.log(this.form.from_date)
      console.log(this.form.to_date)
    },
    onSubmit() {
      this.$refs.newReportForm.validate(valid => {
        if (valid) {
          // alert('submit!')
          this.createReport()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.newReportForm.resetFields()
    },
    createReport() {
      this.createLoading = true
      this.form.year = this.createForMonth ? new Date().getFullYear() : 0
      this.form.month = this.createForMonth ? this.selectedMonth + 1 : 0
      this.form.account_id = this.accountId
      this.form.project_id = this.projectId
      const url = '/admin/analytics/reports'
      return apiClient.post(url, this.form).then((response) => {
        const newReport = response.data.data
        // console.log(response)
        this.$notification.success({
          message: 'Создан новый файл',
          description: newReport.file_path.replace(/^.*[\\/]/, ''),
        })
        this.resetForm()
        console.log(this.downloadAfterCreate)
        this.submitModal(newReport, this.downloadAfterCreate)
        this.createLoading = false
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating new report',
          description: error.message,
        })
        this.createLoading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
